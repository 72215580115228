<template>
  <!-- 小企业 利润表-->
  <div>
    <div class="big_box4">
      <div class="top_title3">
        <h5>{{title}}</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>{{durationOfTaxTime}}</span></h6>
          <h6 class="center" v-if="title == '利润表(季报)'"><span>{{periodLrb}}</span></h6>
          <h6 class="center" v-else-if="title == '利润表(月报)'"><span>{{periodLrb2}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:46%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:25%;">本年累计金额</td>
          <td class="center" style="width:25%;" v-if="title == '利润表(季报)'">本期金额</td>
          <td class="center" style="width:25%;" v-else-if="title == '利润表(月报)'">本月金额</td>
        </tr>
      </table>
      <div class="fixed_box_table" :style="{height:contentStyleObj}">
        <table class="content" cellspacing="0">
          <tr>
            <td class="weight" style="width:46%;">一、营业收入</td>
            <td class="center" style="width:4%;">1</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="list[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="list[0].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>减：营业成本</td>
            <td class="center">2</td>
            <qzf-td :disabled="false" v-model:amount="list[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[1].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>税金及附加</td>
            <td class="center">3</td>
            <qzf-td :disabled="false" v-model:amount="list[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[2].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>其中：消费税<button @click="qslyShow('消费税',list[3].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">4</td>
            <qzf-td :disabled="false" v-model:amount="list[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[3].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>营业税<button @click="qslyShow('营业税',list[4].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">5</td>
            <qzf-td :disabled="false" v-model:amount="list[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[4].periodTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td>城市维护建设税<button @click="qslyShow('城市维护建设税',list[5].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">6</td>
            <qzf-td :disabled="false" v-model:amount="list[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[5].periodTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td>资源税<button @click="qslyShow('资源税',list[6].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">7</td>
            <qzf-td :disabled="false" v-model:amount="list[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[6].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>土地增值税<button @click="qslyShow('土地增值税',list[7].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">8</td>
            <qzf-td :disabled="false" v-model:amount="list[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[7].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>城镇土地使用税、房产税、车船税、印花税<button @click="qslyShow('城镇土地使用税、房产税、车船税、印花税',list[8].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">9</td>
            <qzf-td :disabled="false" v-model:amount="list[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[8].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>教育附加、矿产资源、排污费<button @click="qslyShow('教育附加、矿产资源、排污费',list[9].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">10</td>
            <qzf-td :disabled="false" v-model:amount="list[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[9].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>销售费用</td>
            <td class="center">11</td>
            <qzf-td :disabled="false" v-model:amount="list[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[10].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>其中：商品维修费<button @click="qslyShow('商品维修费',list[11].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">12</td>
            <qzf-td :disabled="false" v-model:amount="list[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[11].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>广告费和业务宣传费<button @click="qslyShow('广告费和业务宣传费',list[12].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">13</td>
            <qzf-td :disabled="false" v-model:amount="list[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[12].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>管理费用</td>
            <td class="center">14</td>
            <qzf-td :disabled="false" v-model:amount="list[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[13].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>其中：开办费<button @click="qslyShow('开办费',list[14].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">15</td>
            <qzf-td :disabled="false" v-model:amount="list[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[14].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>业务招待费<button @click="qslyShow('业务招待费',list[15].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">16</td>
            <qzf-td :disabled="false" v-model:amount="list[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[15].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>研究费用<button @click="qslyShow('研究费用',list[16].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">17</td>
            <qzf-td :disabled="false" v-model:amount="list[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[16].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>财务费用</td>
            <td class="center">18</td>
            <qzf-td :disabled="false" v-model:amount="list[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[17].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>其中：利息费用(收入以-号填列)<button @click="qslyShow('利息费用',list[18].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">19</td>
            <qzf-td :disabled="false" v-model:amount="list[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[18].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>加：投资收益<button @click="qslyShow('投资收益',list[19].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">20</td>
            <qzf-td :disabled="false" v-model:amount="list[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[19].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight">二、营业利润</td>
            <td class="center">21</td>
            <qzf-td :disabled="true" v-model:amount="list[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[20].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>加：营业外收入</td>
            <td class="center">22</td>
            <qzf-td :disabled="false" v-model:amount="list[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[21].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>其中：政府补助<button @click="qslyShow('政府补助',list[22].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">23</td>
            <qzf-td :disabled="false" v-model:amount="list[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[22].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>减：营业外支出</td>
            <td class="center">24</td>
            <qzf-td :disabled="false" v-model:amount="list[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[23].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>其中：坏账损失<button @click="qslyShow('坏账损失',list[24].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">25</td>
            <qzf-td :disabled="false" v-model:amount="list[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[24].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>无法收回的长期债券投资损失<button @click="qslyShow('无法收回的长期债券投资损失',list[25].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">26</td>
            <qzf-td :disabled="false" v-model:amount="list[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[25].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>无法收回的长期股权投资损失<button @click="qslyShow('无法收回的长期股权投资损失',list[26].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">27</td>
            <qzf-td :disabled="false" v-model:amount="list[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[26].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>自然灾害等不可抗力因素造成的损失<button @click="qslyShow('自然灾害等不可抗力因素造成的损失',list[27].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">28</td>
            <qzf-td :disabled="false" v-model:amount="list[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[27].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>税收滞纳金<button @click="qslyShow('税收滞纳金',list[28].type)" class="qsly" v-if="title == '利润表(季报)' && typeName != '历史数据' && !this.startAccountPeriod">取数来源</button></td>
            <td class="center">29</td>
            <qzf-td :disabled="false" v-model:amount="list[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[28].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight">三、利润总额</td>
            <td class="center">30</td>
            <qzf-td :disabled="true" v-model:amount="list[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[29].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>减：所得税费用</td>
            <td class="center">31</td>
            <qzf-td :disabled="false" v-model:amount="list[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[30].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight">四、净利润</td>
            <td class="center">32</td>
            <qzf-td :disabled="true" v-model:amount="list[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[31].periodTotal" @change="changeList()"></qzf-td>
          </tr>
        </table>
      </div>
    </div>
    <div class="save_box" v-if="this.typeName != '历史数据'&& $buttonStatus('kjbb_bc')">
      <el-button @click="saveLrb" type="primary" size="small"  v-if="this.startAccountPeriod">
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
        <span  > 保存</span>
      </el-button>
      <qzf-button @success="saveLrb" type="primary" size="small"  v-else>
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
        <span  > 保存</span>
      </qzf-button>
    </div>
    <!-- 新增弹窗取数来源 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogConversion" title="新增取数来源"  class="button_bg">
      <el-form ref="dataForm" label-position="left" label-width="60px" class="clearfix" style="overflow:hidden">
        <el-button style="margin-bottom:10px;" size="small" @click="addUnits" round>新增取数来源</el-button>
        <div style="margin-bottom:10px;" v-for="(item,index) in qslyList" :key="index">
          <span>科目：</span>
          <subject-list v-model:subjectId="item.subjectId"></subject-list>
          <!-- <el-select filterable v-model="item.code" placeholder="请选择" size="small">
            <el-option v-for="item in subjectList" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select> -->
          <i style="font-size:20px;margin-left:10px;cursor: pointer;" @click="delUnits(index)" class="iconfont icon-shanchu"></i>       
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogConversion = false" size="small">取 消</el-button>
          <el-button type="primary" @click="conversionSave()" size="small">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit ,eaLrbOriginLists ,eaLrbOriginSave} from '@/api/taxCalculation.js'
import { hisLrbInfo,hisLrbMonthInfo } from '@/api/old.js'
export default {
  name: 'lrbTable',
  components: { },
  props: {
    title: String,
    typeName: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      list: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      periodLrb: '',
      periodLrb2: '',
      type:0,
      qslyList: [],
      dialogConversion: false,
      subjectList:[],
      comName:'',
      taxNo:'',
      durationOfTaxTime:'',
      num:0
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(356)
  },
  methods: {
    changeList(){
      //营业利润
      this.list[20].yearTotal = (this.list[0].yearTotal  - 
                                      this.list[1].yearTotal  -
                                      this.list[2].yearTotal  -  
                                      this.list[10].yearTotal  - 
                                      this.list[13].yearTotal  - 
                                      this.list[17].yearTotal  + 
                                      this.list[19].yearTotal ).toFixed(2)*1
      this.list[20].periodTotal = (this.list[0].periodTotal  - 
                                        this.list[1].periodTotal  - 
                                        this.list[2].periodTotal  - 
                                        this.list[10].periodTotal  - 
                                        this.list[13].periodTotal  - 
                                        this.list[17].periodTotal  + 
                                        this.list[19].periodTotal  ).toFixed(2)*1
       //利润总额
      this.list[29].yearTotal = (this.list[20].yearTotal +
                                      this.list[21].yearTotal -
                                      this.list[23].yearTotal ).toFixed(2)*1
      this.list[29].periodTotal = (this.list[20].periodTotal +
                                        this.list[21].periodTotal -
                                        this.list[23].periodTotal ).toFixed(2)*1
      // 净利润
      this.list[31].yearTotal = ( this.list[29].yearTotal - 
                                      this.list[30].yearTotal ).toFixed(2)*1
      this.list[31].periodTotal = ( this.list[29].periodTotal - 
                                        this.list[30].periodTotal ).toFixed(2)*1
    },
    getList(period){
       if(this.title == '利润表(季报)'){
         if(this.typeName == '历史数据'){
          let periodZC
          if(period){
            periodZC = period
          } else {
            periodZC = this.$store.getters['user/comInfo'].period;
          }
          hisLrbInfo({period:periodZC}).then(res=>{
            if(res.data.data.info){
              this.list = res.data.data.info
              // this.type = res.data.data.type
            }
          })
        } else if(this.typeName == '会计报表') {
          if(this.startAccountPeriod){
            let param = {
              tableName: 'kj_lrb',
              period:this.startAccountPeriod,
              comId:this.comId
            }
            gsInfo(param).then(res=>{
              if(res.data.data.info){
                this.list = res.data.data.info
                this.durationOfTaxTime = this.startAccountPeriod
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
              }
            })
          }else{
            gsInfo({tableName: 'kj_lrb'}).then(res=>{
              if(res.data.data.info){
                this.list = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }
        }  
      }else if(this.title == '利润表(月报)'){
        if(this.typeName == '历史数据'){
          let periodZC
          if(period){
            periodZC = period
          } else {
            periodZC = this.$store.getters['user/comInfo'].period;
          }
          hisLrbMonthInfo({period:periodZC}).then(res=>{
            if(res.data.data.info){
              this.list = res.data.data.info
              // this.type = res.data.data.type
            }
          })
        } else if(this.typeName == '会计报表') {
          if(this.startAccountPeriod){
            let param = {
              tableName: 'kj_lrb',
              period:this.startAccountPeriod,
              comId:this.comId,
              itemName:'month',
            }
            gsInfo(param).then(res=>{
              if(res.data.data.info){
                this.list = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }else{
            gsInfo({tableName: 'kj_lrb',itemName:'month'}).then(res=>{
              if(res.data.data.info){
                this.list = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }
        }
        
      }
    },
    saveLrb(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'kj_lrb',
          items:this.list,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        let paramMon = {
          tableName:'kj_lrb',
          items:this.list,
          itemName:'month',
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        if(this.title == '利润表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '利润表(月报)'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }else{
        let param = {
          tableName:'kj_lrb',
          items:this.list
        }
        let paramMon = {
          tableName:'kj_lrb',
          items:this.list,
          itemName:'month'
        }
        if(this.title == '利润表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '利润表(月报)'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }
    },
    // 取数来源
    qslyShow(type,num){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      console.log(num)
      this.qslyList = [] //放到接口
      this.qslxType = type
      this.num = num
      eaLrbOriginLists({name:type}).then(res=>{
        this.qslyList = res.data.data.list
        this.dialogConversion = true
      })
    },
    // 删除取数来源
    delUnits(index){
      this.qslyList.splice(index, 1)
    },
    addUnits(){
      this.qslyList.push({
        subjectId: '',
      })
    },
    // 保存取数来源
    conversionSave(){
      let param = {name:this.qslxType,num:Number(this.num),items:this.qslyList}
      eaLrbOriginSave(param).then(res=>{
        if(res.data.msg != 'success'){
          return
        }
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.dialogConversion = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h5{
    font-size: 20px;
    color: #333;
    font-weight: 700;
    text-align: center;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    // position: relative;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
      // position: absolute;
      // bottom: 0;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2){
      padding-top: 10px;
    }
  }
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 3px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>
