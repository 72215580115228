<template>
  <!-- 小企业 利润表-->
  <div>
    <div class="big_box4">
      <div class="top_title3">
        <h5 v-if="title == '利润表(季报)'">利润表(季报)（适用未执行新金融准则的一般企业）</h5>
        <h5 v-else-if="title == '利润表(月报)'">利润表(月报)（适用未执行新金融准则的一般企业）</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>{{durationOfTaxTime}}</span></h6>
          <h6 class="center" v-if="title == '利润表(季报)'"><span>{{periodLrb}}</span></h6>
          <h6 class="center" v-else-if="title == '利润表(月报)'"><span>{{periodLrb2}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg">
          <td class="center" style="width:46%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:25%;">本年累计金额</td>
          <td class="center" style="width:25%;" v-if="title == '利润表(季报)'">本期金额</td>
          <td class="center" style="width:25%;" v-else-if="title == '利润表(月报)'">本月金额</td>
        </tr>
      </table>
      <div class="fixed_box_table" :style="{height:contentStyleObj}">
        <table class="content" cellspacing="0">
          <tr>
            <td class="weight" style="width:46%;">一、营业收入</td>
            <td class="center" style="width:4%;">1</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[0].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[0].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>减：营业成本</td>
            <td class="center">2</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[1].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[1].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>税金及附加</td>
            <td class="center">3</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[2].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[2].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>销售费用</td>
            <td class="center">4</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[3].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[3].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>管理费用</td>
            <td class="center">5</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[4].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[4].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>研发费用</td>
            <td class="center">6</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[5].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[5].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>财务费用</td>
            <td class="center">7</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[6].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[6].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k">其中：利息费用</td>
            <td class="center">8</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[7].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[7].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k">利息收入</td>
            <td class="center">9</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[8].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[8].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>加：其他收益</td>
            <td class="center">10</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[9].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[9].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <!-- <tr>
              -->
          <tr>
            <td>投资收益（损失以“—”号填列）</td>
            <td class="center">11</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[10].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[10].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>其中：对联营企业和合营企业的投资收益</td>
            <td class="center">12</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[11].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[11].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>公允价值变动收益（损失以“—”号填列）</td>
            <td class="center">13</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[12].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[12].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>资产减值损失（损失以“-”号填列）</td>
            <td class="center">14</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[13].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[13].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>资产处置收益（损失以“-”号填列）</td>
            <td class="center">15</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[14].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[14].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight ">二、营业利润</td>
            <td class="center">16</td>
            <qzf-td style="width:25%;" :disabled="true" v-model:amount="attachArr[15].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="true" v-model:amount="attachArr[15].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>加：营业外收入</td>
            <td class="center">17</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[16].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[16].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>减：营业外支出</td>
            <td class="center">18</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[17].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[17].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight">三、利润总额（亏损总额以“—”号填列）</td>
            <td class="center">19</td>
            <qzf-td style="width:25%;" :disabled="true" v-model:amount="attachArr[18].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="true" v-model:amount="attachArr[18].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>减：所得税费用</td>
            <td class="center">20</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[19].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[19].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight">四、净利润（净亏损以“—”号填列）</td>
            <td class="center">21</td>
            <qzf-td style="width:25%;" :disabled="true" v-model:amount="attachArr[20].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="true" v-model:amount="attachArr[20].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>（一）持续经营净利润（净亏损以“-”号填列）</td>
            <td class="center">22</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[21].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[21].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>（二）终止经营净利润（净亏损以“-”号填列）</td>
            <td class="center">23</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[22].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[22].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="weight ">五、其他综合收益的税后净额</td>
            <td class="center">24</td>
            <qzf-td style="width:25%;" :disabled="true" v-model:amount="attachArr[23].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="true" v-model:amount="attachArr[23].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>（一）不能重分类进损益的其他综合收益</td>
            <td class="center">25</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[24].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[24].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>1.重新计量设定受益计划变动额</td>
            <td class="center">26</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[25].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[25].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>2.权益法下不能转损益的其他利益综合收益</td>
            <td class="center">27</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[26].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[26].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>（二）将重分类进损益的其他综合收益</td>
            <td class="center">28</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[27].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[27].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>1.权益法下可转损益的其他综合收益</td>
            <td class="center">29</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[28].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[28].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>2.可供出售金融资产公允价值变动损益</td>
            <td class="center">30</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[29].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[29].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>3.持有至到期投资重分类可供出售金融资产损益</td>
            <td class="center">31</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[30].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[30].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>4.现金流量期损益的有效部分</td>
            <td class="center">32</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[31].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[31].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>5.外币财务报表折算差额</td>
            <td class="center">33</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[32].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[32].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="weight">六、综合收益总额</td>
            <td class="center">34</td>
            <qzf-td style="width:25%;" :disabled="true" v-model:amount="attachArr[33].yearTotal" @change="changeList()">
            </qzf-td>
            <qzf-td style="width:25%;" :disabled="true" v-model:amount="attachArr[33].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight ">七、每股收益</td>
            <td class="center">35</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[34].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[34].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>（一）基本每股收益</td>
            <td class="center">36</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[35].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[35].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td>（二）稀释每股收益</td>
            <td class="center">37</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[36].yearTotal"
              @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[36].periodTotal"
              @change="changeList()"></qzf-td>
          </tr>

        </table>
      </div>
    </div>
    <div class="save_box" v-if="title == '利润表(季报)'&& $buttonStatus('kjbb_bc')">
      <el-button @click="saveLrb" type="primary" size="small"  v-if="this.startAccountPeriod">
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
        <span  > 保存</span>
      </el-button>
      <qzf-button @success="saveLrb" type="primary" size="small"  v-else>
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
        <span  > 保存</span>
      </qzf-button>
    </div>
  </div>

</template>

<script>
import { gsInfo, gsEdit } from '@/api/taxCalculation.js'
import { hisLrbInfo, hisLrbMonthInfo } from '@/api/old.js'
export default {
  name: 'lrbTable2',
  components: {},
  props: {
    title: String,
    typeName: String,
    startAccountPeriod: {
      default: '',
      type: String
    },
    comId: {
      default: 0,
      type: Number
    },
  },
  data() {
    return {
      attachArr: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      contentStyleObj: {}, //高度变化
      periodLrb: '',
      periodLrb2: '',
      type: 0,
      comName: '',
      taxNo: '',
      durationOfTaxTime: ''
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj = this.$getHeight(356)
  },

  methods: {
    changeList() {
      //营业利润
      this.attachArr[15].yearTotal = (this.attachArr[0].yearTotal -
        this.attachArr[1].yearTotal -
        this.attachArr[2].yearTotal -
        this.attachArr[3].yearTotal -
        this.attachArr[4].yearTotal -
        this.attachArr[5].yearTotal -
        this.attachArr[6].yearTotal +
        this.attachArr[9].yearTotal +
        this.attachArr[10].yearTotal +
        this.attachArr[12].yearTotal +
        this.attachArr[13].yearTotal +
        this.attachArr[14].yearTotal).toFixed(2) * 1
      this.attachArr[15].periodTotal = (this.attachArr[0].periodTotal -
        this.attachArr[1].periodTotal -
        this.attachArr[2].periodTotal -
        this.attachArr[3].periodTotal -
        this.attachArr[4].periodTotal -
        this.attachArr[5].periodTotal -
        this.attachArr[6].periodTotal +
        this.attachArr[9].periodTotal +
        this.attachArr[10].periodTotal +
        this.attachArr[12].periodTotal +
        this.attachArr[13].periodTotal +
        this.attachArr[14].periodTotal).toFixed(2) * 1
      //利润总额
      this.attachArr[18].yearTotal = (this.attachArr[15].yearTotal +
        this.attachArr[16].yearTotal -
        this.attachArr[17].yearTotal).toFixed(2) * 1
      this.attachArr[18].periodTotal = (this.attachArr[15].periodTotal +
        this.attachArr[16].periodTotal -
        this.attachArr[27].periodTotal).toFixed(2) * 1
      //净利润
      this.attachArr[20].yearTotal = (this.attachArr[18].yearTotal -
        this.attachArr[19].yearTotal).toFixed(2) * 1
      this.attachArr[20].periodTotal = (this.attachArr[18].periodTotal -
        this.attachArr[19].periodTotal).toFixed(2) * 1
      //五、其他综合收益的税后净额 
      this.attachArr[23].yearTotal = (this.attachArr[24].yearTotal +
        this.attachArr[27].yearTotal).toFixed(2) * 1
      this.attachArr[23].periodTotal = (this.attachArr[24].periodTotal +
        this.attachArr[27].periodTotal).toFixed(2) * 1
      // 综合收益总额
      this.attachArr[33].yearTotal = (this.attachArr[20].yearTotal +
        this.attachArr[23].yearTotal).toFixed(2) * 1
      this.attachArr[33].periodTotal = (this.attachArr[20].periodTotal +
        this.attachArr[23].periodTotal).toFixed(2) * 1
    },
    getList(period) {
      if (this.title == '利润表(季报)') {
        if (this.typeName == '历史数据') {
          let periodZC
          if (period) {
            periodZC = period
          } else {
            periodZC = this.$store.getters['user/comInfo'].period;
          }
          hisLrbInfo({ period: periodZC }).then(res => {
            if (res.data.data.info) {
              this.attachArr = res.data.data.info
              // this.type = res.data.data.type
            }
          })
        } else if (this.typeName == '会计报表') {
          if (this.startAccountPeriod) {
            let param = {
              tableName: 'kj_lrb',
              period: this.startAccountPeriod,
              comId: this.comId
            }
            gsInfo(param).then(res => {
              if (res.data.data.info.length == 37) {
                this.attachArr = res.data.data.info
                this.durationOfTaxTime = this.startAccountPeriod
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
              }
            })
          } else {
            gsInfo({ tableName: 'kj_lrb' }).then(res => {
              if (res.data.data.info.length == 37) {
                this.attachArr = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }
        }
      } else if (this.title == '利润表(月报)') {
        if (this.typeName == '历史数据') {
          let periodZC
          if (period) {
            periodZC = period
          } else {
            periodZC = this.$store.getters['user/comInfo'].period;
          }
          hisLrbMonthInfo({ period: periodZC }).then(res => {
            if (res.data.data.info) {
              this.attachArr = res.data.data.info
            }
          })
        } else if (this.typeName == '会计报表') {
          if (this.startAccountPeriod) {
            let param = {
              tableName: 'kj_lrb',
              period: this.startAccountPeriod,
              comId: this.comId,
              itemName: 'month',
            }
            gsInfo(param).then(res => {
              if (res.data.data.info.length == 37) {
                this.attachArr = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          } else {
            gsInfo({ tableName: 'kj_lrb', itemName: 'month' }).then(res => {
              if (res.data.data.info.length == 37) {
                this.attachArr = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }
        }
      }
    },
    saveLrb() {
      if (this.startAccountPeriod) {
        let param = {
          tableName: 'kj_lrb',
          items: this.attachArr,
          period: this.startAccountPeriod,
          comId: this.comId,
        }
        let paramMon = {
          tableName: 'kj_lrb',
          items: this.attachArr,
          itemName: 'month',
          period: this.startAccountPeriod,
          comId: this.comId,
        }
        if (this.title == '利润表(季报)') {
          gsEdit(param).then(res => {
            if (res.data.msg == "success") {
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        } else if (this.title == '利润表(月报)') {
          gsEdit(paramMon).then(res => {
            if (res.data.msg == "success") {
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      } else {
        let param = {
          tableName: 'kj_lrb',
          items: this.attachArr
        }
        let paramMon = {
          tableName: 'kj_lrb',
          items: this.attachArr,
          itemName: 'month'
        }
        if (this.title == '利润表(季报)') {
          gsEdit(param).then(res => {
            if (res.data.msg == "success") {
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        } else if (this.title == '利润表(月报)') {
          gsEdit(paramMon).then(res => {
            if (res.data.msg == "success") {
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 180px !important;
}

.save_box {
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}

.content {
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;

  span {
    line-height: 28px;
    font-size: 14px;
  }

  tr {
    padding: 0;
    margin: 0;
  }

  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    line-height: 28px;
    padding: 0 6px;
    color: #333;
    font-size: 14px;
  }

  input {
    width: 100%;
    line-height: 28px;
    padding: 0 6px;
    font-size: 14px;
    padding-right: 20px;
  }
}

.left_k {
  text-indent: 2em;
}

.left_b {
  text-indent: 4em;
}

.left_x {
  text-indent: 5em;
}

.left_d {
  text-indent: 8em;
}

.top_title3 {
  width: 100%;
  margin: 0 auto;

  h5 {
    font-size: 20px;
    color: #333;
    font-weight: 700;
    text-align: center;
  }

  .bottom_font {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;

    // position: relative;
    h6 {
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
      // position: absolute;
      // bottom: 0;
    }

    h6:nth-child(1) {
      padding-top: 10px;
    }

    h6:nth-child(2) {
      padding-top: 10px;
    }
  }
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.weight {
  font-weight: 700;
}

.row_box {
  border-bottom: 1px solid #eee;
}

.big_box4 {
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}

.qsly {
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>
