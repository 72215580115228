<template>
  <!-- 企业会计制度 利润表-->
  <div>
    <div class="big_box4">
      <div class="top_title3">
        <h5>{{title}}</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>{{durationOfTaxTime}}</span></h6>
          <h6 class="center" v-if="title == '利润表(季报)'"><span>{{periodLrb}}</span></h6>
          <h6 class="center" v-else-if="title == '利润表(月报)'"><span>{{periodLrb2}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:46%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:25%;">本年累计金额</td>
          <td class="center" style="width:25%;" v-if="title == '利润表(季报)'">本期金额</td>
          <td class="center" style="width:25%;" v-else-if="title == '利润表(月报)'">本月金额</td>
        </tr>
      </table>
      <div class="fixed_box_table" :style="{height:contentStyleObj}">
        <table class="content" cellspacing="0">
          <tr>
            <td class="weight" style="width:46%;">一、主营业务收入</td>
            <td class="center" style="width:4%;">1</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[0].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k">减：主营业务成本</td>
            <td class="center">2</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[1].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b">主营业务税⾦及附加</td>
            <td class="center">3</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[2].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight">⼆、主营业务利润（亏损以“－”号填列）(1-2-3)</td>
            <td class="center">4</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[3].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k">加：其他业务利润（亏损以“－”号填列</td>
            <td class="center">5</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[4].periodTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_k">减：营业费⽤</td>
            <td class="center">6</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[5].periodTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_b">管理费⽤</td>
            <td class="center">7</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[6].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b">财务费⽤</td>
            <td class="center">8</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[7].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight">三、营业利润（亏损以“－”号填列）(4+5-6-7-8)</td>
            <td class="center">9</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[8].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k">加：投资收益（损失以“－”号填列）</td>
            <td class="center">10</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[9].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b">补贴收⼊</td>
            <td class="center">11</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[10].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b">营业外收⼊</td>
            <td class="center">12</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[11].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k">减：营业外⽀出</td>
            <td class="center">13</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[12].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight">四、利润总额（亏损总额以“－”号填列）(9+10+11+12-13)</td>
            <td class="center">14</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[13].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k">减：所得税</td>
            <td class="center">15</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[14].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight">五、净利润（净亏损以“－”号填列）(14-15)</td>
            <td class="center">16</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[15].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="center weight" colspan="2">项目</td>
            <td class="center weight">本年累计数</td>
            <td class="center weight">上年实际数</td>
          </tr>
          <tr>
            <td colspan="2">1．出售、处置部⻔或被投资单位所得收益</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[16].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2">2．⾃然灾害发⽣的损失</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[17].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2">3．会计政策变更增加（或减少）利润总额</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[18].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2">4、会计估计变更增加（或减少）利润总额</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[19].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2">5、债务重组损失</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[20].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2">6、其他</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[21].periodTotal" @change="changeList()"></qzf-td>
          </tr>
        </table>
      </div>
    </div>
    <div class="save_box" v-if="title == '利润表(季报)' && $buttonStatus('kjbb_bc')">
      <el-button @click="saveLrb" type="primary" size="small"  v-if="this.startAccountPeriod">
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
        <span  > 保存</span>
      </el-button>
      <qzf-button @success="saveLrb" type="primary" size="small"  v-else>
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
        <span  > 保存</span>
      </qzf-button>
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'zcfzTable',
  components: { },
  props: {
    title: String,
    typeName: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      attachArr: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      periodLrb: '',
      periodLrb2: '',
      type:0,
      comName:'',
      taxNo:'',
      durationOfTaxTime:''
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(356)
  },
  methods: {
    changeList(){
      //主营业务利润
      this.attachArr[3].yearTotal = ( this.attachArr[0].yearTotal - 
                                      this.attachArr[1].yearTotal -
                                      this.attachArr[2].yearTotal ).toFixed(2)*1
      this.attachArr[3].periodTotal = ( this.attachArr[0].periodTotal - 
                                        this.attachArr[1].periodTotal - 
                                        this.attachArr[2].periodTotal ).toFixed(2)*1
      //营业利润
      this.attachArr[8].yearTotal = (  this.attachArr[3].yearTotal +
                                       this.attachArr[4].yearTotal -
                                       this.attachArr[5].yearTotal -
                                       this.attachArr[6].yearTotal -
                                       this.attachArr[7].yearTotal).toFixed(2)*1
      this.attachArr[8].periodTotal = ( this.attachArr[3].periodTotal +
                                        this.attachArr[4].periodTotal -
                                        this.attachArr[5].periodTotal -
                                        this.attachArr[6].periodTotal -
                                        this.attachArr[7].periodTotal).toFixed(2)*1
       //利润总额
      this.attachArr[13].yearTotal = (this.attachArr[8].yearTotal +
                                      this.attachArr[9].yearTotal +
                                      this.attachArr[10].yearTotal +
                                      this.attachArr[11].yearTotal -
                                      this.attachArr[12].yearTotal ).toFixed(2)*1
      this.attachArr[13].periodTotal = (this.attachArr[8].periodTotal +
                                        this.attachArr[9].periodTotal +
                                        this.attachArr[10].periodTotal +
                                        this.attachArr[11].periodTotal -
                                        this.attachArr[12].periodTotal ).toFixed(2)*1
      // 净利润
      this.attachArr[15].yearTotal = ( this.attachArr[13].yearTotal - 
                                      this.attachArr[14].yearTotal ).toFixed(2)*1
      this.attachArr[15].periodTotal = ( this.attachArr[13].periodTotal - 
                                        this.attachArr[14].periodTotal ).toFixed(2)*1
    },
    getList(){
      if(this.title == '利润表(季报)'){
        if(this.startAccountPeriod){
          let param = {
            tableName: 'kj_lrb',
            period:this.startAccountPeriod,
            comId:this.comId
          }
          gsInfo(param).then(res=>{
            if(res.data.data.info){
              this.attachArr = res.data.data.info
              this.durationOfTaxTime = this.startAccountPeriod
              this.comName = res.data.data.name
              this.taxNo = res.data.data.taxNo
            }
          })
        }else{
          gsInfo({tableName: 'kj_lrb'}).then(res=>{
            if(res.data.data.info){
              this.attachArr = res.data.data.info
              this.comName = res.data.data.name
              this.taxNo = res.data.data.taxNo
              this.durationOfTaxTime = res.data.data.time
            }
          })
        }
      }else if(this.title == '利润表(月报)'){
        if(this.startAccountPeriod){
          let param = {
            tableName: 'kj_lrb',
            period:this.startAccountPeriod,
            comId:this.comId,
            itemName:'month',
          }
          gsInfo(param).then(res=>{
            if(res.data.data.info){
              this.attachArr = res.data.data.info
              this.comName = res.data.data.name
              this.taxNo = res.data.data.taxNo
              this.durationOfTaxTime = res.data.data.time
            }
          })
        }else{
          gsInfo({tableName: 'kj_lrb',itemName:'month'}).then(res=>{
            if(res.data.data.info){
              this.attachArr = res.data.data.info
              this.comName = res.data.data.name
              this.taxNo = res.data.data.taxNo
              this.durationOfTaxTime = res.data.data.time
            }
          })
        }
      }
    },
    saveLrb(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'kj_lrb',
          items:this.attachArr,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        let paramMon = {
          tableName:'kj_lrb',
          items:this.attachArr,
          itemName:'month',
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        if(this.title == '利润表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '利润表(月报)'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }else{
        let param = {
          tableName:'kj_lrb',
          items:this.attachArr
        }
        let paramMon = {
          tableName:'kj_lrb',
          items:this.attachArr,
          itemName:'month'
        }
        if(this.title == '利润表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '利润表(月报)'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 6px;
    font-size: 14px;
    padding-right:20px;
  }
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h5{
    font-size: 20px;
    color: #333;
    font-weight: 700;
    text-align: center;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    // position: relative;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
      // position: absolute;
      // bottom: 0;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2){
      padding-top: 10px;
    }
  }
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>
